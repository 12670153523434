<template>
  <div>
    <!-- Address Create -->
    <!-- Title -->
    <div class="form-group">
      <label class="custom-label" for="address-title">Adres Başlığı</label>
      <input
        type="text"
        class="form-control"
        name="address-title"
        id="address-title"
        placeholder="Ev, İş Yeri vb."
        v-model="shared.newAddress.name"
      />
    </div>
    <!-- ./Title -->

    <div class="row">
      <!-- Address Type -->
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label class="custom-label" for="country">Adres Tipi</label>
          <v-select
            label="name"
            placeholder="Adres Tipi"
            v-bind:reduce="(a) => a.id"
            v-bind:options="session.contactTypes"
            v-model="shared.newAddress.contact_type_id"
          ></v-select>
        </div>
      </div>
      <!-- ./Address Type -->
      <!-- Country -->
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label class="custom-label" for="country">Ülke</label>
          <v-select
            id="country"
            label="name"
            placeholder="Ülke"
            v-bind:options="shared.countries"
            v-model="shared.newAddress.country"
            v-on:input="getCities"
          ></v-select>
        </div>
      </div>
      <!-- ./Country -->

      <!-- City -->
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label class="custom-label" for="city">Şehir</label>
          <v-select
            id="city"
            label="name"
            placeholder="Şehir"
            v-bind:options="shared.cities"
            v-bind:disabled="!shared.newAddress.country"
            v-model="shared.newAddress.city"
            v-on:input="getDistricts"
          ></v-select>
        </div>
      </div>
      <!-- ./City -->

      <!-- District -->
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label class="custom-label" for="district">İlçe</label>
          <v-select
            id="city"
            label="name"
            placeholder="İlçe"
            v-bind:options="shared.districts"
            v-bind:disabled="!shared.newAddress.city"
            v-model="shared.newAddress.district"
            v-on:input="getNeighborhoods"
          ></v-select>
        </div>
      </div>
      <!-- ./District -->

      <!-- Neighborhood -->
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label class="custom-label" for="neighborhood">Semt</label>
          <v-select
            id="city"
            label="name"
            placeholder="İlçe"
            v-bind:options="shared.neighborhoods"
            v-bind:disabled="!shared.newAddress.district"
            v-model="shared.newAddress.neighborhood"
            v-on:input="changePostalCode"
          ></v-select>
        </div>
      </div>
      <!-- ./Neighborhood -->

      <!-- Postal Code -->
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label class="custom-label" for="postal-code">Posta Kodu</label>
          <input
            type="text"
            class="form-control"
            name="postal-code"
            id="postal-code"
            placeholder="Posta Kodu"
            v-model="shared.newAddress.postal_code"
          />
        </div>
      </div>
      <!-- ./Postal Code -->
    </div>

    <!-- Full Address -->
    <div class="form-group">
      <label class="custom-label" for="full-address">Açık Adres</label>
      <textarea
        name="full-address"
        id="full-address"
        class="form-control"
        placeholder="Açık Adres"
        v-model="shared.newAddress.address"
      ></textarea>
    </div>
    <!-- ./Full Address -->

    <div class="text-center">
      <span class="btn btn-primary" v-on:click="addNewAddress">
        <i class="fas fa-plus"></i>
        <span class="ml-2">Ekle</span>
      </span>
    </div>
    <!-- Address Create -->
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "NewAddress",
  methods: {
    ...mapMutations("shared", ["setPostalCode", "clearAddress"]),
    ...mapActions("shared", [
      "createAddress",
      "getCountries",
      "getCities",
      "getDistricts",
      "getNeighborhoods",
    ]),
    changePostalCode(neighborhood) {
      this.shared.newAddress.postal_code = neighborhood.code;
    },
    addNewAddress() {
      let require = this.checkRequire();
      if (require) {
        this.createAddress({
          onSuccess: (result) => {
            if (result && result.data) {
              if (result.data.status == "success") {
                let data = {
                  id: result.data.id,
                  name: this.shared.newAddress.name,
                  postal_code: this.shared.newAddress.postal_code,
                  address: this.shared.newAddress.address,
                };
                this.$emit("on-save", this.shared.newAddress);

                this.clearAddress();
                this.$swal({
                  title: "İşlem Başarılı",
                  text: "Adres Başarıyla Eklendi",
                  icon: "success",
                });
              } else {
                this.$swal({
                  title: "Hata",
                  text: result.data.message,
                  icon: "error",
                });
              }
            }
          },
          onError: (error) => {
            if (error && error.response) {
              this.$swal({
                title: "Hata",
                text: error.response.data.message,
                icon: "error",
              });
            }
          },
        });
      }
    },
    checkRequire() {
      const address = this.shared.newAddress;

      if (address.name == null) {
        this.$swal({
          title: "Hata",
          text: "Lütfen Adres Başlığı Girin",
          icon: "error",
        });
      } else if (!address.country || address.country == null) {
        this.$swal({
          title: "Hata",
          text: "Lütfen Ülke seçin",
          icon: "error",
        });
      } else if (!address.city || address.city == null) {
        this.$swal({
          title: "Hata",
          text: "Lütfen Şehir seçin",
          icon: "error",
        });
      } else if (!address.district || address.district == null) {
        this.$swal({
          title: "Hata",
          text: "Lütfen İlçe seçin",
          icon: "error",
        });
      } else if (!address.postal_code || address.postal_code == null) {
        this.$swal({
          title: "Hata",
          text: "Lütfen Posta Kodu girin",
          icon: "error",
        });
      } else if (!address.address || address.address == null) {
        this.$swal({
          title: "Hata",
          text: "Lütfen Açık Adres girin",
          icon: "error",
        });
      } else {
        return true;
      }
    },
  },
  computed: {
    ...mapState(["shared", "session"]),
  },
  mounted() {
    this.getCountries();
  },
};
</script>
