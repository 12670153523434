<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <div class="form-group">
          <v-select
            label="name"
            class="custom-v-select-sm"
            placeholder="Ülke"
            v-bind:options="countries"
            v-model="computedData.country"
            v-bind:disabled="!edit"
            v-bind:clearable="false"
            v-on:input="getCityOptions"
          >
            <span slot="no-options">Gösterilecek sonuç yok</span>
            <template #selected-option="value">
              <div
                class="text-truncate v-select__selected"
                v-tooltip.bottom="value.name"
              >
                <strong>{{ value.name }}</strong>
              </div>
            </template>
          </v-select>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="form-group">
          <v-select
            label="name"
            class="custom-v-select-sm"
            placeholder="Şehir"
            v-bind:options="cities"
            v-model="computedData.city"
            v-bind:disabled="!edit"
            v-bind:clearable="false"
            v-on:input="getDistrictOptions"
          >
            <span slot="no-options">Gösterilecek sonuç yok</span>
            <template #selected-option="value">
              <div
                class="text-truncate v-select__selected"
                v-tooltip.bottom="value.name"
              >
                <strong>{{ value.name }}</strong>
              </div>
            </template>
          </v-select>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="form-group">
          <v-select
            label="name"
            class="custom-v-select-sm"
            placeholder="İlçe"
            v-bind:options="districts"
            v-model="computedData.district"
            v-bind:disabled="!edit"
            v-bind:clearable="false"
            v-on:input="getNeighborhoodOptions"
          >
            <span slot="no-options">Gösterilecek sonuç yok</span>
            <template #selected-option="value">
              <div
                class="text-truncate v-select__selected"
                v-tooltip.bottom="value.name"
              >
                <strong>{{ value.name }}</strong>
              </div>
            </template>
          </v-select>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-3">
        <v-select
          label="name"
          class="custom-v-select-sm"
          placeholder="Semt"
          v-bind:options="neighborhoods"
          v-model="computedData.neighborhood"
          v-on:input="changePostalCode"
          v-bind:disabled="!edit"
          v-bind:clearable="false"
        >
          <span slot="no-options">Gösterilecek sonuç yok</span>
          <template #selected-option="value">
            <div
              class="text-truncate v-select__selected"
              v-tooltip.bottom="value.name"
            >
              <strong>{{ value.name }}</strong>
            </div>
          </template>
        </v-select>
      </div>
      <div class="col col-lg-4 mb-3 mb-lg-0">
        <input
          v-tooltip.top="'Posta Kodu'"
          type="text"
          class="form-control form-control-sm"
          placeholder="Posta Kodu"
          v-model="computedData.postal_code"
        />
      </div>
      <div class="col-12 col-lg-4 mb-3 text-left">
        <div class="btn btn-sm btn-outline-primary" v-on:click="onEdit">
          <i class="fas fa-edit"></i>
          <span class="d-inline d-lg-none ml-2">Düzenle</span>
        </div>
      </div>
    </div>
    <textarea
      v-bind:id="componentId"
      v-bind:name="componentId"
      cols="30"
      rows="10"
      class="form-control address"
      v-model="computedData.address"
    ></textarea>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Address",
  data() {
    return {
      edit: false,
      countries: [],
      cities: [],
      districts: [],
      neighborhoods: [],
    };
  },
  methods: {
    ...mapActions("address", [
      "getCountries",
      "getCities",
      "getDistricts",
      "getNeighborhoods",
    ]),
    onEdit() {
      this.edit = !this.edit;
      if (this.edit) {
        this.getAddressList();
      }
    },
    getAddressList() {
      this.countries = [];
      this.cities = [];
      this.districts = [];
      this.neighborhoods = [];

      this.getCountries({
        onSuccess: (result) => {
          this.countries = result.data.items;
          this.getCityOptions();
        },
      });
    },
    getCityOptions() {
      const country = this.computedData.country;
      if (country && country.id) {
        this.getCities({
          id: country.id,
          onSuccess: (result) => {
            this.cities = result.data.items;
            this.getDistrictOptions();
          },
        });
      }
    },
    getDistrictOptions() {
      const city = this.computedData.city;
      if (city && city.id) {
        this.getDistricts({
          id: city.id,
          onSuccess: (result) => {
            this.districts = result.data.items;
            this.getNeighborhoodOptions();
          },
        });
      }
    },
    getNeighborhoodOptions() {
      const district = this.computedData.district;
      if (district && district.id) {
        this.getNeighborhoods({
          id: district.id,
          onSuccess: (result) => {
            this.neighborhoods = result.data.items;
          },
        });
      }
    },
    changePostalCode() {
      const neighborhood = this.computedData.neighborhood;
      if (neighborhood && neighborhood.code) {
        this.computedData.postal_code = neighborhood.code;
      }
    },
  },
  props: {
    name: {
      default: "",
    },
    error: {
      default: "",
    },
    data: null,
  },
  model: {
    prop: "data",
  },
  computed: {
    computedData() {
      return this.data;
    },
    componentId() {
      return this.name + "-address";
    },
  },
};
</script>
<style lang="scss" scoped>
.address {
  max-height: 100px;
  resize: none;
}
</style>
